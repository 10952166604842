// FE Links
export const DIRECT_TO_INITIAL_URL = '/direct-to-initial/schedule/';
export const INSURANCE_FORM_URL = '/forms/insurance/';
export const ASK_A_DIETITIAN_URL = '/ask-a-dietitian/';

// APIs
export const LEADS_API = '/api/leads';
export const DISCOVERY_REASONS_API = '/api/reasons/discovery';
export const APPOINTMENTS_API = '/api/appointments';
export const STG_API_URL = 'https://dev-cdn.culinahealth-test.com';
export const APPOINTMENTS_ASK_A_DIETITIAN_API = `${APPOINTMENTS_API}/ask-a-dietitian`;
export const INITIAL_URL = `${APPOINTMENTS_API}/initial`;
export const DIETITIAN_URL = `/api/dietitians/`;
export const AAD_APPOINTMENT_URL = `${APPOINTMENTS_API}/ask-a-dietitian/`;

// meeting types
export const DC_TYPE = 'discovery_call';
export const DTI_TYPE = 'direct_to_initial';
export const AAD_TYPE = 'ask_a_dietitian';
export type AppointmentType =
  | typeof DC_TYPE
  | typeof DTI_TYPE
  | typeof AAD_TYPE;

// package types
export type PackageType =
  | 'Essentials'
  | 'Core'
  | 'Enhanced'
  | 'Initial Session'
  | '6 Session Package';

// error messages
export const REQUIRED_FIELD = 'Field is required.';
